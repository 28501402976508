@import '~antd/dist/antd.css';
/* @import '~react-grid-layout/css/styles.css'; */
/* @import '~react-resizable/css/styles.css'; */
/* node_modules/react-grid-layout/css/styles.css */
/* node_modules/react-resizable/css/styles.css */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
